import { AutocompleteValue } from "@mui/material";
import {
  ALGOLIA_INDICES,
  ALGOLIA_SEARCH_ZIP_CODES,
  COUNTRY_DE,
  EMPTY_ALGOLIA_QUERY,
} from "core/consts";
import { Validation } from "core/types";
import AlgoliaSelect, {
  AlgoliaSelectPresenterProps,
} from "dsl/atoms/AlgoliaSelect";
import { CSSProperties } from "react";
import { BasicDoc, Hit } from "react-instantsearch-core";

export type ZipcodeHit = Hit<
  BasicDoc & {
    __position: string;
    _geoloc: { lat: number; lon: number };
    city: string;
    code: string;
    federal_state: string;
  }
>;

export function getAlgoliaZipcodeLabel({
  city,
  code,
  federal_state,
}: Pick<ZipcodeHit, "city" | "code" | "federal_state">) {
  return [code, city, federal_state].truthy().join(", ");
}

function getHitRender() {
  return ({ _geoloc, city, code, federal_state }: ZipcodeHit) => {
    const label = getAlgoliaZipcodeLabel({
      city,
      code,
      federal_state,
    });

    return {
      id: code,
      label,
      latitude: _geoloc.lat,
      longitude: _geoloc.lon,
      value: code,
    };
  };
}

export type ZipcodeSelectValue = {
  id: any;
  label: string;
  latitude: number;
  longitude: number;
  value: any;
};

export type ZipcodesSelectProps<
  T extends ZipcodeSelectValue = ZipcodeSelectValue,
  Multiple extends boolean | undefined = undefined,
> = {
  ListboxProps?: AlgoliaSelectPresenterProps["ListboxProps"];
  ariaLabel?: string;
  ariaLabelledBy?: string;
  connected?: boolean;
  country?: string;
  elementName: string;
  errorOverride?: boolean;
  federalState?: string;
  id?: string;
  label?: string;
  multiple?: Multiple;
  noOptionsText?: string;
  onChange?: (value: AutocompleteValue<T, Multiple, false, false>) => void;
  pasteContext?: { buttonText: string; tooltipText: string };
  placeholder: string;
  required?: boolean;
  validation?: Validation;
  value?: AutocompleteValue<T, Multiple, false, false>;
  withPaste?: boolean;
  withPortal?: boolean;
  zIndex?: CSSProperties["zIndex"];
};

export default function ZipcodesSelect<
  T extends ZipcodeSelectValue = ZipcodeSelectValue,
  Multiple extends boolean | undefined = undefined,
>({
  ariaLabel,
  ariaLabelledBy,
  connected,
  country,
  elementName,
  errorOverride,
  federalState,
  id,
  label,
  ListboxProps,
  multiple,
  noOptionsText,
  onChange,
  pasteContext,
  placeholder,
  required,
  validation,
  value,
  withPaste,
  withPortal,
  zIndex,
}: ZipcodesSelectProps<T, Multiple>) {
  return (
    <AlgoliaSelect
      algoliaAnalyticsName={ALGOLIA_SEARCH_ZIP_CODES}
      ariaLabel={ariaLabel}
      ariaLabelledBy={ariaLabelledBy}
      autoHighlight={!multiple}
      closeMenuOnSelect={!multiple}
      connected={connected}
      defaultQuery={
        (value as any)?.value && typeof (value as any).value == "string"
          ? (value as any).value
          : EMPTY_ALGOLIA_QUERY
      }
      elementName={elementName}
      id={id}
      errorOverride={errorOverride}
      filters={
        federalState
          ? `federal_state:${federalState}`
          : `country:${country || COUNTRY_DE}`
      }
      filterSelectedOptions={multiple}
      indexName={ALGOLIA_INDICES.ZIPCODE}
      label={label}
      ListboxProps={ListboxProps}
      multiple={multiple}
      noOptionsText={noOptionsText}
      onChange={onChange}
      pasteContext={pasteContext}
      placeholder={placeholder}
      renderHits={getHitRender()}
      required={required}
      searchWithoutTyping
      truncateMenuItems
      useShortValue
      validation={validation}
      value={value as any}
      withPaste={withPaste}
      withPortal={withPortal}
      zIndex={zIndex}
    />
  );
}
